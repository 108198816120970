<template>
  <div>
    <van-nav-bar :fixed="true" class="van_nav_bar_view" left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="$router.go(-1)"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">编辑收货地址</span>
      </template>
    </van-nav-bar>
    <!--表单-->
    <div class="from_view">
      <div class="input_view">
        <span>联系人</span>
        <input
          type="text"
          v-model="addr.contactPerson"
          placeholder="请填写联系人姓名"
        />
      </div>
      <div class="input_view">
        <span>手机号</span>
        <input
          type="text"
          v-model="addr.phone"
          placeholder="请填写联系人手机号码"
        />
      </div>
      <!-- <div class="address_view">
        <span class="code_view">验证码</span>
        <input type="text" v-model="areaCode" placeholder="请输入验证码" />
        <span class="get_code_view" @click="captcha_sendSms">获取验证码</span>
      </div> -->
      <div class="address_view">
        <div class="address_text_view">
          <span class="first_span_view">收货地址</span>
          <input
            class="second_span_view"
            v-model="addr.address"
            placeholder="请输入收货地址"
            @click="toSearchAddress"
          />
        </div>
        <van-icon class="img_view" size="22px" name="arrow" color="#CCCCCC" />
      </div>
      <div class="xx_address_class">
        <span>详细地址</span>
        <textarea
          type="text"
          v-model="addr.houseNumber"
          placeholder="门牌号，如，1栋2单元301"
        />
      </div>
      <div class="upload_photo_view">
        <!-- <img src="../../../assets/upload_img_bg.png" alt="" /> -->
        <van-uploader v-model="fileList" :after-read="afterRead" />
        <span>上传取菜地方图片</span>
      </div>
      <div class="switch_view">
        <span>设为默认地址</span>
        <van-switch
          style="margin-right: 12px"
          v-model="checked"
          active-color="#00B05A"
          size="46px 28px"
          inactive-color="#dcdee0"
        />
      </div>
    </div>
    <van-dialog
      v-model="show"
      style="font-size: 15px"
      width="310px"
      message="您未上传取菜地方图片，如果无法联系配送员，请让您的管家协助您取菜"
      confirm-button-text="我知道了"
      confirm-button-color="#1677FF"
    >
    </van-dialog>
    <div class="bottom_btn_sub_view">
      <van-button @click="saveInfo('del')">删除</van-button>
      <van-button @click="saveInfo('save')">保存</van-button>
    </div>
  </div>
</template>

<script>
import {
  imgApp,
  app2,
  upload_upImg,
  receiptDelete,
  receiptUpdate,
  captcha_sendSms,
} from "@/service/api/index.js";
import { Toast } from "vant";

export default {
  data() {
    return {
      fileList: [],
      checked: false,
      show: false,
      name: "Celine",
      areaCode: "",
      isDefault: "",
      phone: "",
      imgPath: "",
      addressContent:
        "31 huilongguan street, changping district, Beijing, China",
    };
  },
  mounted() {
    if(this.addr.isDefault == "ENABLE"){
      this.checked = true;
    }else{
      this.checked = false;
    }
    this.fileList = [
      {
        url: `${app2}/${this.addr.takeImg}`,
        isImage: true,
      },
    ];
    this.imgPath = this.addr.takeImg;
    if (this.$route.query.ress) {
      console.log(this.$route.query.lat,this.$route.query.lng)
      this.addr.longitude = this.$route.query.lng;
      this.addr.latitude = this.$route.query.lat;
      this.addr.address = this.$route.query.ress;
    }
  },
  computed: {
    addr: {
      get() {
        return JSON.parse(this.$route.query.addr);
      },
    },
  },
  methods: {
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload_upImg(formData).then((res) => {
        if (res.status == 200) {
          this.imgPath = res.data;
          Toast("上传成功");
          this.fileList = [
            {
              url: app2 + "/" + res.data,
              isImage: true,
            },
          ];
        }
      });
    },
    saveInfo(type) {
      if (type == "del") {
        receiptDelete({ id: this.addr.id }).then((res) => {
          if (res.status == 200) {
            Toast("删除成功");
            this.$router.push("/address");
          }
        });
      }
      if (type == "save") {
        if (this.checked) {
          this.isDefault = "ENABLE";
        } else {
          this.isDefault = "DISABLE";
        }
        receiptUpdate({
          phone: this.addr.phone,
          contactPerson: this.addr.contactPerson,
          address: this.addr.address,
          houseNumber: this.addr.houseNumber,
          isDefault: this.isDefault,
          takeImg: this.imgPath,
          userId: this.addr.userId,
          id: this.addr.id,
          latitude: this.addr.latitude,
          longitude: this.addr.longitude,
        }).then((res) => {
          if (res.status == 200) {
            Toast("修改成功");
            this.$router.push("/address");
          }
        });
      }
    },
    toCodeSelection(option) {
      this.$router.push({
        path: "/areaCodeSelection",
        query: { option: option, addr: JSON.stringify(this.addr),form:"/editAddress" },
      });
    },
    toSearchAddress() {
      this.$router.replace({
        path: "/mapAddress",
        query: {
          addr: this.$route.query.addr,
          form: "/editAddress",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .van_nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .from_view {
    max-width: 540px;
  }
  .bottom_btn_sub_view {
    max-width: 540px;
  }
}
.from_view {
  width: 100%;
  position: fixed;
  background: white;
  top: 45px;
  .input_view {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    border-bottom: 0.5px solid #eeeeee;
    flex-direction: row;
    align-items: center;
    span {
      width: 83px;
      font-size: 15px;
      color: #333333;
    }
    input {
      width: 252.5px;
      height: 24px;
      margin-right: 11px;
      border: none;
      font-size: 15px;
      margin-left: 15px;
      color: black;
    }
    input::input-placeholder {
      color: #cccccc;
    }
  }
  .address_view {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    justify-content: space-between;
    border-bottom: 0.5px solid #eeeeee;
    flex-direction: row;
    align-items: center;
    .address_text_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        width: 86.5px;
        display: block;
        font-size: 15px;
        color: #333333;
      }
      input {
        width: 252.5px;
        height: 100%;
        border: none;
        background: none;
        font-size: 15px;
        color: black;
      }
    }
    .img_view {
      width: 15.35px;
      height: 22px;
      margin-right: 30px;
    }
  }
  .xx_address_class {
    width: 100%;
    background: white;
    display: flex;
    margin-left: 12px;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 14px;
    height: 72px;
    span {
      width: 83px;
      font-size: 15px;
      color: #333333;
    }
    textarea {
      width: 245px;
      margin-right: 19px;
      border: none;
      font-size: 15px;
      margin-left: 15px;
      color: black;
    }
    textarea::teatarea-placeholder {
      color: #cccccc;
    }
  }
  .upload_photo_view {
    width: 100%;
    height: 128px;
    display: flex;
    position: relative;
    flex-direction: column;
    img:first-of-type {
      width: 110px;
      height: 70px;
      margin: 16px 0px 8px 12px;
    }
    span {
      font-size: 14px;
      color: #acacac;
      margin-left: 12px;
    }
    .close_view {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 7px;
      left: 111px;
    }
  }
  .switch_view {
    height: 48px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 0.5px solid #eeeeee;
    margin-left: 12px;
    justify-content: space-between;
    span {
      font-size: 15px;
      color: #3f3f3f;
    }
  }
}
.bottom_btn_sub_view {
  width: 100%;
  height: 57px;
  background: white;
  position: fixed;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  button:first-of-type {
    width: 157px;
    height: 36px;
    margin-left: 20px;
    margin-right: 20px;
    background: white;
    border-radius: 18px;
    border: 0.6px solid #cacacc;
    color: #3f3f3f;
    font-size: 13px;
  }
  button:last-of-type {
    width: 157px;
    height: 36px;
    margin-left: 20px;
    margin-right: 20px;
    background: linear-gradient(to right, #01b15b, #49d0a2);
    border-radius: 18px;
    border: none;
    color: white;
    font-size: 13px;
  }
}
</style>